import * as React from "react";
import Footer from "../Footer";
import logo from "../../images/logo_bolt.png";
import "./Backoffice.sass";
import { navigate } from "gatsby";
import { useEffect, useState } from "react";
import Calendar from "./Calendar";
import Orders from "./Orders";
import Meetings from "./Meetings";
import Devis from "./Devis";
import { getToken, logout } from "../../helpers";

const Backoffice = () => {
  const [nav, setNav] = useState("entretiens");
  const [prestations, setPrestations] = useState([]);

  const logoutButton = () => {
    logout(() => console.log("logged out from dashboard"));
    navigate("/backoffice/login");
  };

  useEffect(() => {
    getPrestations();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getPrestations = async () => {
    const token = getToken();
    const request = {
      method: "get",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };

    let response = await fetch(
      "https://legalify-back.herokuapp.com" + "/prestations",
      request
    );
    let jsonResponse = await response.json();
    if (jsonResponse.success) {
      console.log(jsonResponse.data);
      // note: we use to filter prestations on paid, but disabled for now.
      setPrestations(jsonResponse.data.filter((p) => p.is_paid));
    }
  };

  const renderComponent = () => {
    if (nav == "commandes") {
      return <Orders prestations={prestations}></Orders>;
    }
    if (nav == "calendrier") {
      return <Calendar></Calendar>;
    }

    if (nav == "entretiens") {
      return <Meetings prestations={prestations}></Meetings>;
    }

    if (nav == "devis") {
      return <Devis prestations={prestations}></Devis>;
    }
    return <h3>No matching component</h3>;
  };

  return (
    <div id="bo-holder">
      <div id="flex">
        <div id="navbar">
          <img src={logo}></img>
          <ul>
            <li
              className={nav == "entretiens" ? "selected" : ""}
              onClick={() => setNav("entretiens")}
            >
              Entretiens
            </li>
            <li
              className={nav == "commandes" ? "selected" : ""}
              onClick={() => setNav("commandes")}
            >
              Commandes
            </li>
            <li
              className={nav == "devis" ? "selected" : ""}
              onClick={() => setNav("devis")}
            >
              Devis
            </li>
            <li
              className={nav == "calendrier" ? "selected" : ""}
              onClick={() => setNav("calendrier")}
            >
              Calendrier
            </li>
          </ul>
          <h4 onClick={logoutButton}>☒ Logout</h4>
        </div>
        <div id="render-zone">
          <h2>{nav.charAt(0).toUpperCase() + nav.slice(1)}</h2>
          {renderComponent()}
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
};

export default Backoffice;
