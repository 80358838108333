import * as React from "react";
import "./Calendar.sass";
import { getToken } from "../../helpers";

class Calendar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      date: "",
      selectedTiles: [],
      dateObj: null,
      slots: [],
      slotsCopy: [],
      slotsTaken: [],
      isChanged: false,
      requestPending: false,
    };
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  blockSlot = async () => {
    // only block if a tile is selected
    //slots/block
    if (this.state.requestPending) {
      return;
    }
    this.setState({
      requestPending: true,
    });
    let slotDates = [];
    this.state.slots.forEach((slot, index) => {
      let countDifference = slot.count - this.state.slotsCopy[index].count;
      for (let i = 0; i < countDifference; i++) {
        let d = new Date(slot.date);
        slotDates.push(d);
      }
    });

    try {
      const token = getToken();
      const request = {
        method: "post",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify({
          slotDates: slotDates,
        }),
      };
      let response = await fetch(
        "https://legalify-back.herokuapp.com" + "/slots/block",
        request
      );
      let jsonResponse = await response.json();
      if (jsonResponse.success) {
        this.getSlots();
        let slotsCopy = await JSON.parse(JSON.stringify(this.state.slots));
        slotsCopy.forEach((slot, index) => {
          slot.date = new Date(slot.date);
        });
        this.setState({
          slotsCopy: slotsCopy,
          isChanged: false,
          requestPending: false,
        });
      } else {
        this.setState({
          requestPending: false,
        });
        alert("Failed to block slots");
        return;
      }
    } catch (err) {
      this.setState({
        requestPending: false,
      });
      return;
    }
  };

  getSlots = async () => {
    try {
      const request = {
        method: "get",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      };
      let response = await fetch(
        "https://legalify-back.herokuapp.com" + "/slots", //"https://legalify-back.herokuapp.com" "http://localhost:3001"
        request
      );
      let jsonResponse = await response.json();
      if (jsonResponse.success) {
        let slotsTaken = jsonResponse.data;
        //CHECK LATER
        this.setState({
          slotsTaken: slotsTaken,
        });
      } else {
        alert("Failed to get free slots");
        return;
      }
    } catch (err) {
      return;
    }
  };

  async componentDidMount() {
    this.getSlots();
  }

  resetSlots = async () => {
    let slots = this.state.slotsCopy;
    let slotsCopy = await JSON.parse(JSON.stringify(slots));
    slotsCopy.forEach((slot, index) => {
      slot.date = new Date(slot.date);
    });
    this.setState({
      slots: slots,
      slotsCopy: slotsCopy,
      isChanged: false,
    });
  };

  selectAllSlots = async () => {
    let slots = this.state.slotsCopy;
    let slotsCopy = await JSON.parse(JSON.stringify(slots));
    slots.forEach((slot) => {
      slot.count = 2;
    });
    slotsCopy.forEach((slot, index) => {
      slot.date = new Date(slot.date);
    });
    this.setState({
      slots: slots,
      slotsCopy: slotsCopy,
      isChanged: true,
    });
    // setTimeout(() => {
    //   this.setAppointmentsTimes("9-0", "19-0");
    // }, 100);
  };

  //args as h-mins format eg: 14-30, 6-0
  setAppointmentsTimes = async (startTime, endTime) => {
    if (this.state.slotsTaken.length === 0) {
      return;
    }
    let startHrsMins = startTime.split("-");
    let endHrsMins = endTime.split("-");
    let timeStart = new Date(this.state.dateObj);

    timeStart.setHours(parseInt(startHrsMins[0]));
    timeStart.setMinutes(parseInt(startHrsMins[1]));
    timeStart.setSeconds(0);
    timeStart.setMilliseconds(0);

    let timeEnd = new Date(this.state.dateObj);
    timeEnd.setHours(parseInt(endHrsMins[0]));
    timeEnd.setMinutes(parseInt(endHrsMins[1]));
    timeEnd.setSeconds(0);
    timeEnd.setMilliseconds(0);

    let slots = [];
    let now = new Date();

    //set pool n+1 : morning -> 9-14, afternoon -> 14-9
    let endingNextPool = new Date();
    if (now.getHours() < 14 && now.getHours() > 9) {
      endingNextPool.setDate(now.getDate() + 1);
      endingNextPool.setHours(9);
      endingNextPool.setMinutes(0);
      endingNextPool.setSeconds(0);
      endingNextPool.setMilliseconds(0);
    } else {
      if (now.getHours() > 9) {
        endingNextPool.setDate(now.getDate() + 1);
      }
      endingNextPool.setHours(14);
      endingNextPool.setMinutes(0);
      endingNextPool.setSeconds(0);
      endingNextPool.setMilliseconds(0);
    }
    for (let i = 1; timeStart < timeEnd; i++) {
      let newSlot = {
        date: new Date(timeStart.getTime()),
        available: true,
        count: 0,
      };
      this.state.slotsTaken.forEach((slot) => {
        let slotDate = new Date(slot);

        if (newSlot.date.getTime() === slotDate.getTime()) {
          if (newSlot.count > 0) {
            newSlot.available = false;
            newSlot.count = 2;
          } else {
            newSlot.count = newSlot.count + 1;
          }
        }
        if (newSlot.date.getTime() < endingNextPool.getTime()) {
          newSlot.available = false;
          newSlot.count = 2;
        }
      });
      slots.push(newSlot);
      timeStart = new Date(timeStart.getTime() + 30 * 60000);
    }

    if (this.state.price === 150) {
      for (let i = 0; i < slots.length; i++) {
        if (
          (i + 1 < slots.length &&
            !slots[i + 1].available &&
            slots[i].available) ||
          (slots[i].date.getHours() === 18 && slots[i].date.getMinutes() === 30)
        ) {
          slots[i].available = false;
        }
      }
    }

    let slotsCopy = await JSON.parse(JSON.stringify(slots));
    slotsCopy.forEach((slot, index) => {
      slot.date = new Date(slot.date);
    });
    this.setState({
      slots: slots,
      slotsCopy: slotsCopy,
    });
  };

  // this.setDateTime(
  //   index,
  //   slot.date.getHours(),
  //   slot.date.getMinutes(),
  //   slot.available
  // );

  setDateTime = (tile, hours, minutes, available) => {
    if (!available) {
      return;
    }
    let date = new Date(this.state.dateObj);
    let slots = this.state.slots;
    // slots[tile] = {

    // }
    slots[tile].count = slots[tile].count === 2 ? 0 : slots[tile].count + 1;
    if (this.state.slotsCopy[tile].count > slots[tile].count) {
      slots[tile].count = this.state.slotsCopy[tile].count;
    }
    date.setHours(hours);
    date.setMinutes(minutes);
    this.setState({
      slots: slots,
      isChanged: true,
      dateObj: date,
      selectedTile: tile, // default is unselected
    });
  };

  removeDateTime = (tile, hours, minutes, available) => {
    if (!available) {
      return;
    }
    let date = new Date(this.state.dateObj);
    date.setHours(hours);
    date.setMinutes(minutes);
    this.setState({
      dateObj: date,
      selectedTile: tile, // default is unselected
    });
  };

  returnFullDateFr = () => {
    if (this.state.dateObj === null) {
      return "";
    }
    let selectedDate = new Date(this.state.date);
    let str1 = selectedDate.toLocaleDateString("fr-FR", { weekday: "long" });
    let str2 = this.state.dateObj.getDate();
    let str3 = selectedDate.toLocaleString("fr-FR", { month: "long" });
    let str4 = this.state.dateObj.getFullYear();
    return (
      str1[0].toUpperCase() +
      str1.substring(1) +
      " " +
      str2 +
      " " +
      str3 +
      " " +
      str4
    );
  };

  renderSlotTiles = () => {
    if (this.state.slots.length === 0) {
      return "";
    } else {
      return (
        <div>
          <div id="date-fr">{this.returnFullDateFr()}</div>
          <div id="tiles-container">
            {this.state.slots.map((slot, index) => {
              let slotClass = "";
              switch (slot.count) {
                case 0:
                  slotClass = "slut slut-available";
                  break;
                case 1:
                  slotClass = "slut slut-half-available";

                  break;
                case 2:
                  slotClass = "slut slut-unavailable";

                  break;
              }
              return (
                <div
                  className={
                    this.state.selectedTile === index
                      ? slotClass + " slut-selected"
                      : slotClass
                  }
                  onClick={() => {
                    this.setDateTime(
                      index,
                      slot.date.getHours(),
                      slot.date.getMinutes(),
                      slot.available
                    );
                  }}
                >
                  {slot.date.getHours() +
                    ":" +
                    slot.date.getMinutes() +
                    (index % 2 === 0 ? "0" : "")}
                </div>
              );
            })}
          </div>
        </div>
      );
    }
  };

  renderDateDescFr = () => {
    if (
      this.state.dateObj !== null &&
      typeof this.state.dateObj.getMonth === "function"
    ) {
      return <div>{this.renderSlotTiles()}</div>;
    } else {
      return <div></div>;
    }
  };

  parseIntoDate = (dateString) => {
    let separStrings = dateString.split("-");
    separStrings.map((str, i) => {
      if (str.charAt(0) === "0") {
        separStrings[i] = str.replace("0", "");
      }
    });
    let date = new Date(
      parseInt(separStrings[0]),
      parseInt(separStrings[1]) - 1,
      parseInt(separStrings[2])
    );

    this.setState({
      dateObj: date,
    });
  };

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    if (target.name === "date") {
      this.parseIntoDate(target.value);
      setTimeout(() => {
        this.setAppointmentsTimes("9-0", "19-0");
      }, 100);
      this.setState({
        date: target.value,
        selectedTile: -1,
      });
    } else {
      this.setState({
        [name]: value,
      });
    }
  }

  render() {
    return (
      <div id="calendar-holder">
        <div id="date-n-time-picker-container">
          <label id="date-picker-container">
            Choisissez la date de votre entretien.
            <input
              type="date"
              name="date"
              value={this.state.date}
              onChange={this.handleInputChange}
              className="mini"
            ></input>
          </label>
          {this.renderDateDescFr()}
        </div>
        <button
          className={!this.state.isChanged ? "inactive" : ""}
          disabled={!this.state.isChanged ? true : false}
          onClick={this.blockSlot}
        >
          Sauvegarder changements →
        </button>
        <button onClick={this.selectAllSlots}>Tout Sélectionner</button>
        <button onClick={this.resetSlots}>Réinitialiser</button>
      </div>
    );
  }
}

export default Calendar;
