import * as React from "react";
import "./Orders.sass";
import { useState } from "react";

const Meetings = (props) => {
  const [timeFilter, setTime] = useState("all");
  const [searchFilter, setSearch] = useState("");

  const convert = (dateString) => {
    let d = new Date(dateString);
    return d.toLocaleDateString() + " - " + d.toLocaleTimeString();
  };

  const renderPrestations = () => {
    return (
      <table>
        <tr>
          <th>prestation</th>
          <th>prix</th>
          <th>client</th>
          <th>adresse</th>
          <th>entretien le</th>
          <th>commentaire</th>
          <th>commandé le</th>
        </tr>
        {props.prestations
          .filter((prest) => {
            if (prest.prestation_name.toLowerCase().includes("entretien")) {
              return prest;
            }
          })
          .filter((pr) => {
            if (timeFilter === "all") {
              return pr;
            } else {
              let tmstp = new Date(pr.appointment_date);
              let today = new Date();
              let tomorrow = new Date();
              tomorrow.setDate(tomorrow.getDate() + 1);
              let nextTen = new Date();
              nextTen.setDate(nextTen.getDate() + 10);

              if (
                timeFilter === "today" &&
                tmstp.toDateString() === today.toDateString()
              ) {
                return pr;
              }

              if (
                timeFilter === "tomorrow" &&
                tmstp.toDateString() === tomorrow.toDateString()
              ) {
                return pr;
              }

              if (timeFilter === "10days" && tmstp > nextTen) {
                return pr;
              }
            }
          })
          .filter((prest) => {
            if (searchFilter !== "") {
              if (
                prest.name !== undefined &&
                prest.name.toLowerCase().includes(searchFilter)
              ) {
                return prest;
              }

              if (
                prest.business_name !== undefined &&
                prest.business_name.toLowerCase().includes(searchFilter)
              ) {
                return prest;
              }

              if (
                prest.email !== undefined &&
                prest.email.toLowerCase().includes(searchFilter)
              ) {
                return prest;
              }

              if (
                prest.prestation_id !== undefined &&
                prest.prestation_id.toLowerCase().includes(searchFilter)
              ) {
                return prest;
              }
            } else {
              return prest;
            }
          })
          .sort((a, b) => {
            const dateA = new Date(a.appointment_date);
            const dateB = new Date(b.appointment_date);
            return dateB - dateA;
          })
          .map((p, i) => {
            return (
              <tr key={i}>
                <td className="main c1">
                  {p.prestation_name.charAt(0).toUpperCase() +
                    p.prestation_name.slice(1)}{" "}
                  {p.prestation_id === undefined
                    ? ""
                    : "(" + p.prestation_id + ")"}
                </td>
                <td className="c2">CHF {p.price}</td>
                <td className="c3">
                  {p.business_name}
                  {p.name}
                  <br />☎ {p.telephone}
                  <br />✉ {p.email}
                </td>
                <td className="c3">{p.adresse}</td>
                <td className="c4">
                  {p.appointment_date === null
                    ? "-"
                    : convert(p.appointment_date)}
                </td>
                <td className="meta c5">{renderMeta(p)}</td>
                <td className="c6">{convert(p.created_at)}</td>
              </tr>
            );
          })}
      </table>
    );
  };

  const renderMeta = (p) => {
    if (
      (p.prestation_type === "meet" || p.prestation_type === "call") &&
      p.comment !== undefined
    ) {
      return <span> {p.comment}</span>;
    }

    return "-";
  };

  return (
    <div id="order-holder">
      <input
        type="text"
        placeholder="name, email"
        value={searchFilter}
        onChange={(e) => setSearch(e.target.value)}
      ></input>
      <ul>
        <li
          className={timeFilter === "all" ? "selected" : ""}
          onClick={() => setTime("all")}
        >
          all
        </li>
        <li
          className={timeFilter === "today" ? "selected" : ""}
          onClick={() => setTime("today")}
        >
          aujourd'hui
        </li>
        <li
          className={timeFilter === "tomorrow" ? "selected" : ""}
          onClick={() => setTime("tomorrow")}
        >
          demain
        </li>
        <li
          className={timeFilter === "10days" ? "selected" : ""}
          onClick={() => setTime("10days")}
        >
          prochains 10 jours
        </li>
      </ul>
      {props.prestations.length > 0 ? renderPrestations() : ""}
    </div>
  );
};

export default Meetings;
