import * as React from "react";
import "@fontsource/dm-sans";
import Login from "../components/backoffice/Login";
import Backoffice from "../components/backoffice/Backoffice";
import { Router } from "@reach/router";

// markup
const BO = () => {
  return (
    <Router>
      <Login path="/backoffice/login"></Login>
      <Backoffice path="/backoffice/dashboard"></Backoffice>
    </Router>
  );
};

export default BO;
