import * as React from "react";
import "./Orders.sass";
import { useState } from "react";

const Orders = (props) => {
  /*
- search: nom prénom email
- pop-up if needed to show everything
  */
  const [timeFilter, setTime] = useState("all");
  const [prestFilter, setPrest] = useState("all");
  const [searchFilter, setSearch] = useState("");

  const handleFilterChange = (e) => {
    setPrest(e.target.value);
  };

  const convert = (dateString) => {
    let d = new Date(dateString);
    return d.toLocaleDateString() + " - " + d.toLocaleTimeString();
  };

  const renderPrestations = () => {
    return (
      <table>
        <tr>
          <th>prestation</th>
          <th>prix</th>
          <th>client</th>
          <th>adresse</th>
          <th>détails</th>
          <th>commandé le</th>
        </tr>
        {props.prestations
          .filter((prest) => {
            if (prestFilter === "all") {
              if (!prest.prestation_name.toLowerCase().includes("entretien")) {
                return prest;
              }
            } else {
              if (
                prestFilter === "abos" &&
                prest.prestation_name.toLowerCase().includes("abonnement")
              ) {
                return prest;
              }

              if (
                prestFilter === "contrats" &&
                prest.prestation_name.toLowerCase().includes("contrat")
              ) {
                return prest;
              }

              if (
                prestFilter === "company" &&
                prest.prestation_name.toLowerCase().includes("constitution")
              ) {
                return prest;
              }
            }
          })
          .filter((pr) => {
            if (timeFilter === "all") {
              return pr;
            } else {
              let tmstp = new Date(pr.created_at);
              let today = new Date();
              let tomorrow = new Date();
              tomorrow.setDate(tomorrow.getDate() - 1);
              let last30 = new Date();
              last30.setDate(last30.getDate() - 30);

              if (
                timeFilter === "today" &&
                tmstp.toDateString() === today.toDateString()
              ) {
                return pr;
              }

              if (
                timeFilter === "yesterday" &&
                tmstp.toDateString() === tomorrow.toDateString()
              ) {
                return pr;
              }

              if (timeFilter === "30days" && tmstp > last30) {
                return pr;
              }
            }
          })
          .filter((prest) => {
            if (searchFilter !== "") {
              if (
                prest.name !== undefined &&
                prest.name.toLowerCase().includes(searchFilter)
              ) {
                return prest;
              }

              if (
                prest.business_name !== undefined &&
                prest.business_name.toLowerCase().includes(searchFilter)
              ) {
                return prest;
              }

              if (
                prest.email !== undefined &&
                prest.email.toLowerCase().includes(searchFilter)
              ) {
                return prest;
              }

              if (
                prest.prestation_id !== undefined &&
                prest.prestation_id.toLowerCase().includes(searchFilter)
              ) {
                return prest;
              }
            } else {
              return prest;
            }
          })
          .sort((a, b) => {
            const dateA = new Date(a.created_at);
            const dateB = new Date(b.created_at);
            return dateB - dateA;
          })
          .map((p, i) => {
            return (
              <tr key={i}>
                <td className="main c1">
                  {p.prestation_name.charAt(0).toUpperCase() +
                    p.prestation_name.slice(1)}{" "}
                  {p.prestation_id === undefined
                    ? ""
                    : "(" + p.prestation_id + ")"}
                </td>
                <td className="c2">CHF {p.price}</td>
                <td className="c3">
                  {p.business_name}
                  {p.name}
                  <br />☎ {p.telephone}
                  <br />✉ {p.email}
                </td>
                <td className="c3">{p.adresse}</td>
                <td className="meta c5">
                  {p.comment}
                  {renderMeta(p)}
                </td>
                <td className="c6">{convert(p.created_at)}</td>
              </tr>
            );
          })}
      </table>
    );
  };

  const renderMeta = (p) => {
    if (p.prestation_type === "abo" && p.meta !== undefined) {
      return <span>début: {p.meta.aboStart}</span>;
    }

    if (p.prestation_type === "company" && p.meta !== undefined) {
      return (
        <span>
          associés: {p.meta.associates} <br />
          capital: {p.meta.capital} <br />
          canton: {p.meta.canton} <br />
        </span>
      );
    }

    if (p.prestation_type === "meet" && p.comment !== undefined) {
      return <span>commentaire: {p.comment}</span>;
    }

    return "-";
  };

  return (
    <div id="order-holder">
      <input
        type="text"
        placeholder="name, email"
        value={searchFilter}
        onChange={(e) => setSearch(e.target.value)}
      ></input>
      <ul>
        <li
          className={timeFilter === "all" ? "selected" : ""}
          onClick={() => setTime("all")}
        >
          all
        </li>
        <li
          className={timeFilter === "today" ? "selected" : ""}
          onClick={() => setTime("today")}
        >
          aujourd'hui
        </li>
        <li
          className={timeFilter === "yesterday" ? "selected" : ""}
          onClick={() => setTime("yesterday")}
        >
          hier
        </li>
        <li
          className={timeFilter === "30days" ? "selected" : ""}
          onClick={() => setTime("30days")}
        >
          derniers 30 jours
        </li>
      </ul>
      <select value={prestFilter} onChange={handleFilterChange}>
        <option value="all">all</option>
        <option value="contrats">contrats</option>
        <option value="abos">abos</option>
        <option value="company">sociétés</option>
      </select>
      {props.prestations.length > 0 ? renderPrestations() : ""}
    </div>
  );
};

export default Orders;
