import * as React from "react";
import Footer from "../Footer";
import "./Login.sass";
import { useState } from "react";
import logo from "../../images/logo_bolt.png";
import grid from "../../images/black_grid.png";
import { navigate } from "gatsby";
import { handleLogin } from "../../helpers";

const Login = () => {
  const [prompt, setPrompt] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const clearPrompt = () => setPrompt("");
  const writePrompt = (text) => {
    setPrompt(text);
    setTimeout(clearPrompt, 1000);
  };

  const onButtonClick = async () => {
    // simple validation
    if (username.length < 1 || password.length < 1) {
      writePrompt("Please enter a password and username");
      return;
    }
    const success = await handleLogin(username, password);
    console.log(success);
    if (success) {
      writePrompt("Login successfull");
      console.log("Moving on");
      navigate("/backoffice/dashboard");
    } else {
      writePrompt("Login unsuccessfull");
    }
  };

  return (
    <div id="login-holder">
      <div id="flexy">
        <div id="login">
          <img src={logo}></img>
          <h3>Login</h3>
          <label>
            Username
            <input
              type="text"
              name="mail"
              value={username}
              onChange={(e) => {
                setUsername(e.target.value);
              }}
            ></input>
          </label>
          <label>
            Password
            <input
              type="password"
              name="mail"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            ></input>
          </label>
          <button onClick={onButtonClick}>Login →</button>
          <p>{prompt}</p>
        </div>
        <div id="gate">
          <img src={grid} id="a"></img>
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
};

export default Login;
